<template>
  <div>
    <div class="card p-3 mb-3 shadow-sm">
      <div class="row justify-content-between">
        <div class="col-auto">
          <h5 class="e-text-red">
            Deficiency
          </h5>
        </div>
        <div class="col-auto text-right">
          <button class="e-btn e-btn-green mb-2 px-4 e-text-white mx-2"
                  data-target="#deficiencyNew"
                  data-toggle="modal"
                  v-if="isAllowed('psc.main.deficiency.create') && isOwnVessel('pscReport') && pscGet('inspection.deficiencies')"
          >
            <font-awesome-icon icon="plus" class="mr-2"/>
            Add Deficiency
          </button>
        </div>
      </div>
      <table class="table table-sm table-bordered text-center">
        <thead>
        <tr>
          <th width="1">#</th>
          <th style="width: 40px">Deficiency Code</th>
          <th>Deficiency Description</th>
          <th>Corrective Action</th>
          <th>Preventative Measure</th>
          <th style="width: 85px">Status</th>
          <th style="width: 40px">Action Code</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <template v-if="Array.isArray(pscGet('psc_deficiencies')) && pscGet('psc_deficiencies').length">
          <tr v-for="(deficiency,index) in pscGet('psc_deficiencies')"
              :class="{'e-bg-blue-light-8':deficiency.id===selectedId}">
            <td>{{ index + 1 }}</td>
            <td class="align-middle font-weight-bold">
              {{ deficiency.psc_code_id ?  deficiency.code.code.toUpperCase() : '' }}
            </td>
            <td class="align-middle">{{ deficiency.description | toUpper }}</td>
            <td v-html="deficiency.corrective ? deficiency.corrective.instruction_short : ''"></td>
            <td v-html="deficiency.preventative ? deficiency.preventative.detail_short : ''"></td>
            <td class="align-middle" :class="['bg-'+deficiency.report_status_color]">{{deficiency.report_status_name | toUpper}}</td>

            <td class="font-weight-bold">
              {{(deficiency.psc_action_code_id ? deficiency.action_code.code.toUpperCase():'')+(deficiency.uscg_code_one_id ? deficiency.uscg_code_one.code.toLowerCase():'')+(deficiency.uscg_code_two_id ? deficiency.uscg_code_two.code.toLowerCase() : '')}}
            </td>
            <td class="align-middle">
              <div class="dropdown dropright my-0">
                <button
                  id="actionDropdown"
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="btn btn-sm btn-secondary force-white-all"
                  data-toggle="dropdown">
                  <font-awesome-icon icon="cogs"/>
                </button>
                <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                  <button class="dropdown-item text-secondary font-weight-bolder"
                          @click="viewDeficiency(deficiency,true)"
                          v-if="isAllowed('psc.main.deficiency.view')">
                    <font-awesome-icon icon="eye"/>
                    View
                  </button>
                  <template v-if="user.vessel_id">
                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                            @click="deleteDeficiency(deficiency)"
                            v-if="isOwnVessel('pscReport') && isAllowed('psc.main.deficiency.delete') ">
                      <font-awesome-icon icon="trash" class="force-danger-all"/>
                      Delete
                    </button>
                  </template>
                  <template v-else>
                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                            @click="deleteDeficiency(deficiency)"
                            v-if="isAllowed('psc.main.deficiency.delete') ">
                      <font-awesome-icon icon="trash" class="force-danger-all"/>
                      Delete
                    </button>
                  </template>

                </div>
              </div>
            </td>
          </tr>
        </template>

        </tbody>
      </table>
    </div>

    <div class="card p-3 shadow-sm" v-if="selectedId">
      <div class="row">
        <div class="col-12 text-right">
          <button class="btn btn-secondary btn-xs" :disabled="startPage === 1" @click="setDeficiencyPage(-1)">
            <font-awesome-icon icon="arrow-left" class="force-white-all"></font-awesome-icon>
          </button>
          {{startPage}}
          /
          {{pscGet('psc_deficiencies').length}}
          <button class="btn btn-secondary btn-xs" :disabled="startPage === pscGet('psc_deficiencies').length" @click="setDeficiencyPage(1)">
            <font-awesome-icon icon="arrow-right" class="force-white-all"></font-awesome-icon>
          </button>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-6">
          <h5 class="e-text-red">
            Deficiency Detail
          </h5>
        </div>
        <div class="col-6 text-right">
          <button class="e-btn e-btn-sm e-btn-blue-gray" @click="selectedId=null" >Close</button>
        </div>
      </div>
      <table class="table table-sm table-borderless text-center">
        <tr>
          <td class="align-middle font-weight-bold text-left" style="width:25%">PSC CODE:
            <template v-if="!!deficiency.code">
              ({{deficiency.code.code}}) {{deficiency.code.description}}
            </template>
            <template v-else>
              UNKNOWN CODE
            </template>

          </td>
          <td class="align-middle text-left">
            {{ deficiency.description}}
          </td>
        </tr>
      </table>
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-item nav-link"  id="nav-Detail-tab" data-toggle="tab" role="tab"
             :class="{'active':$route.name==='PscDeficiencyDetail'}"
             :data-target="'PscDeficiencyDetail'"
             aria-controls="nav-Detail" aria-selected="true" @click="setTab('PscDeficiencyDetail')">
            Detail
          </a>
          <a class="nav-item nav-link" id="nav-Cause-tab"
             data-toggle="tab" role="tab"
             :class="{'active':$route.name==='PscDeficiencyCause'}"
             :data-target="'PscDeficiencyCause'"
             aria-controls="nav-Cause" aria-selected="false"
             @click="setTab('PscDeficiencyCause')"
             v-if="isAllowed('psc.main.deficiency.overview.cause.menu')">
            Cause
          </a>
          <a class="nav-item nav-link" id="nav-CorrectiveAction-tab" data-toggle="tab"
             role="tab"
             :class="{'active':$route.name==='PscDeficiencyCorrectiveAction'}"
             :data-target="'PscDeficiencyCorrectiveAction'"
             @click="setTab('PscDeficiencyCorrectiveAction')"
             aria-controls="nav-CorrectiveAction" aria-selected="false"
             v-if="isAllowed('psc.main.deficiency.overview.ca.menu')" >
            Corrective Action
          </a>
          <a class="nav-item nav-link" id="nav-PreventativeMeasures-tab" data-toggle="tab" role="tab"
             :class="{'active':$route.name==='PscDeficiencyPreventativeMeasure'}"
             :data-target="'PscDeficiencyPreventativeMeasure'"
             @click="setTab('PscDeficiencyPreventativeMeasure')"
             aria-controls="nav-PreventiveMeasures" aria-selected="false"
             v-if="isAllowed('psc.main.deficiency.overview.pm.menu')">
            Preventative Measures
          </a>
          <router-link class="nav-item nav-link" id="nav-CompanyInstruction-tab" data-toggle="tab"
                       :data-target="'PscDeficiencyCompanyInstruction'"
             :to="{name:'PscDeficiencyCompanyInstruction',query:{deficiency_id:this.selectedId}}"
             @click="setTab('PscDeficiencyCompanyInstruction')"
             :class="{'active':$route.name==='PscDeficiencyCompanyInstruction'}"
             role="tab"
             aria-controls="nav-CompanyInstruction" aria-selected="false"
             v-if="isAllowed('psc.main.deficiency.overview.ci.menu')">
            Company Instruction
          </router-link>
        </div>
      </nav>

      <div class="tab-content pt-2" id="nav-tabContent">
        <router-view></router-view>
      </div>


    </div>
    <psc-deficiency-new></psc-deficiency-new>
  </div>


</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ObjectMixin from "../../../mixins/ObjectMixin";
import {AlertService} from "../../../services/AlertService";
import PscActionCodeComponent from "./deficiency/PscActionCodeComponent.vue";
import PscDeficiencyNew from "@/components/modules/psc/deficiency/PscDeficiencyNew.vue";
import {permissionMixin} from "@/mixins/permissionMixins";
import pscDeficiencyMixin from "../../../mixins/PscDeficiencyMixin";
import {DataService} from "@/services/DataService";

export default {
  name: "PscDeficiency",
  mixins: [ObjectMixin,permissionMixin,pscDeficiencyMixin],
  data() {
    return {
      selectedId: null,
      startPage:1,
    }
  },
  components: {
    PscActionCodeComponent,
    PscDeficiencyNew
  },
  computed: {
    ...mapGetters(['pscReport','deficiency','user']),
    hasChanges() {
      if (this.toEdit === false) {
        return false;
      }

      let editedData = {};
      let originalData = {};

      switch(this.$route.name) {
        case 'PscDeficiencyDetail' :
          editedData = {
            psc_code_id        : this.deficiency.psc_code_id,
            description        : this.deficiency.description === '' ? null : this.deficiency.description,
            details            : this.deficiency.details === '' ? null : this.deficiency.details,
            psc_action_code_id : this.deficiency.psc_action_code_id,
            uscg_code_one_id   : this.deficiency.uscg_code_one_id,
            uscg_code_two_id   : this.deficiency.uscg_code_two_id,
            uscg_rectify_id    : this.deficiency.uscg_rectify_id
          };
          originalData = this.selected_psc_deficiencies;
          break;
        case 'PscDeficiencyCause' :
          editedData = {
            immediate_cause_id     : this.deficiency.cause.immediate_cause_id,
            immediate_cause_sub_id : this.deficiency.cause.immediate_cause_sub_id,
            immediate_cause_detail : this.deficiency.cause.immediate_cause_detail === '' ? null : this.deficiency.cause.immediate_cause_detail,
            root_cause_id          : this.deficiency.cause.root_cause_id,
            root_cause_child_id    : this.deficiency.cause.root_cause_child_id,
            root_cause_detail      : this.deficiency.cause.root_cause_detail === '' ? null : this.deficiency.cause.root_cause_detail
          };
          originalData = this.selected_psc_deficiencies.cause;
          break;
        case 'PscDeficiencyCorrectiveAction' :
          editedData = {
            instruction                    : this.deficiency.corrective.instruction === '' ? null : this.deficiency.corrective.instruction,
            instruction_date               : this.deficiency.corrective.instruction_date === '' ? null : this.deficiency.corrective.instruction_date,
            action_taken                   : this.deficiency.corrective.action_taken === '' ? null : this.deficiency.corrective.action_taken,
            action_date                    : this.deficiency.corrective.action_date === '' ? null : this.deficiency.corrective.action_date,
            reported_by                    : this.deficiency.corrective.reported_by === '' ? null : this.deficiency.corrective.reported_by,
            is_corrective_action_affective : this.deficiency.corrective.is_corrective_action_affective,
            is_corrective_action_effective : this.deficiency.corrective.is_corrective_action_effective,
            verification_effective_date    : this.deficiency.corrective.verification_effective_date === '' ? null : this.deficiency.corrective.verification_effective_date,
            is_verification_effective      : this.deficiency.corrective.is_verification_effective,
            verification_date              : this.deficiency.corrective.verification_date === '' ? null : this.deficiency.corrective.verification_date
          };
          originalData = this.selected_psc_deficiencies.corrective;
          break;
        case 'PscDeficiencyPreventativeMeasure' :
          editedData = {
            detail              : this.deficiency.preventative.detail === '' ? null : this.deficiency.preventative.detail,
            related_equipment   : this.deficiency.preventative.related_equipment === '' ? null : this.deficiency.preventative.related_equipment,
            related_procedures  : this.deficiency.preventative.related_procedures === '' ? null : this.deficiency.preventative.related_procedures,
            for_risk_assessment : this.deficiency.preventative.for_risk_assessment,
            for_shore_training  : this.deficiency.preventative.for_shore_training
          };
          originalData = this.selected_psc_deficiencies.preventative;
          break;
      }

      return DataService.checkIfChanged(originalData, editedData);
    },
    selected_psc_deficiencies() {
      if (this.pscReport.psc_deficiencies.length === 0 || this.selectedId === null) {
        return [];
      }
      let selectedPscDeficiency = this.pscReport.psc_deficiencies.filter((deficiency) => {
        return deficiency.id === this.selectedId;
      });

      return selectedPscDeficiency[0];
    }
  },
  methods: {
    ...mapActions(['deletePsc', 'getPscReport', 'getPscCodes', 'getPscActionCodes','getUscgCodes']),

    setDeficiencyPage(num){
      this.startPage = this.startPage + num
      const currentPscDeficiency = this.pscGet('psc_deficiencies')[this.startPage - 1]
      this.viewDeficiency(currentPscDeficiency)
    },

    viewDeficiency(deficiency,isFromList=false){
      this.showDeficiency(deficiency)
      const index = this.pscGet('psc_deficiencies').findIndex(def=>def.id === deficiency.id)
      if(isFromList) {
        this.startPage=index + 1
      }
      this.$router.push({name:"PscDeficiencyDetail",query:{tz:Date.now()}})
    },
    setTab(route='PscDeficiencyDetail'){
      this.$router.push({name:route,query:{tz:Date.now()}})
    },
    async deleteDeficiency(deficiency) {
      if (await AlertService.confirmDeleteAlert('DELETION OF DEFICIENCY', 'Do you want to Delete this Deficiency?', 'Yes, Proceed!')) {
        const params = {type: 2, id: deficiency.id}
        const response = await this.deletePsc(params);
        if (response) {
          AlertService.successAlert('PSC Deficiency Deleted Successfully!', 'DELETE DEFICIENCY');
          this.deficiencyForm = null;
          this.selectedId = null;
          await this.getPscReport({id: this.$route.params.id})
        }
      }
    }
  },
  async created() {
    localStorage.removeItem('currentDeficiency')
    await this.getPscCodes({});
    await this.getPscActionCodes({});
    await this.getUscgCodes({});
  },
  destroyed() {
    localStorage.removeItem('currentDeficiency')
  }
}
</script>

<style scoped>
.nav > .nav-link {
  padding: 8px 16px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  color: #435464;
  margin-bottom: -1px;
}

.nav > .active, .nav > .nav-link:hover {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;
}

.nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

table td {
  padding-bottom: .25rem;
}
</style>
